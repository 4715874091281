/* Base styles */
.tenant-input-container {
  display: none;
  max-width: 500px;
  margin: 30px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.tenant-input-container input[type="text"],
.tenant-input-container button {
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}
.copy-message {
  color: green;
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
}
.back-button {
  background-color: #f1f1f1;
  color: #333;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 4px;
}

.back-button:hover {
  background-color: #ddd;
}
/* Greyed-out style for disabled button */
.back-button.disabled {
  background-color: #ccc; /* Light grey background */
  color: #888;           /* Darker grey text */
  cursor: not-allowed;    /* Change cursor to not-allowed */
}

button.delete-selected {
  background-color: red;
}
.back-button:disabled {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}
.file-uploader-box {
  border-width:5px;
  border-style: dotted;
  border-left: none;
  border-right: none;
  border-color: yellow;
  background-color: #666666;
  opacity: 90%;
}
.tenant-input-container input {
  border: 1px solid #ced4da;
  color:#333;
  padding:20px;
  background-color: #f8f9fa;
  border-radius: 4px;
  max-width: 90%;
  flex: 1;
  font-size: 15px;
  text-align: center;
}

.tenant-input-container button {
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tenant-input-container button:hover,
.tenant-input-container button:focus {
  background-color: #0056b3;
}
.filter-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.current-path {
  display:none;
}

.filter-container input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  min-width:200px;
  max-width: 300px; /* Adjust as needed */
  transition: border-color 0.3s ease-in-out;
}

.filter-container input[type="text"]:focus {
  border-color: #007bff; /* Highlight input border when focused */
  outline: none;
}
.folder-creation {
  text-align: center;
}
.folder-creation input {
  width: 200px;
}
.folder-creation form {
  width:100%;
  max-width:480px;
}
.folder-creation {
  background-color: #fff;
  margin-top: 50px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
  width:100%;
  max-width: 40%;
  float:left;
  margin-left:50px;
}
.folder-move {
  background-color: #fff;
  margin-top: 50px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
float:right;
  max-width: 30%;
  margin-right:50px;
  border-style: double;
  border-color: #ff9100;
  border-width:40px;

}

.folder-creation h2, .folder-move h2 {
  margin-top: 0;
}
.folder-creation input, .folder-creation button {
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.warning {
  color: black;
  font-size: 14px;
}
.folder-creation button {
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  border: none;
}
.folder-creation button:hover {
  background-color: #0056b3;
}
.items-per-page-selector {
  font-size: .5em;
  float:left;
  width: 200px;
}

.error {
  color: #dc3545;
  text-align: center;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.modal p {
  color: #333;
  margin: 10px 0;
  font-size: 16px;
}

.modal p:first-of-type {
  font-weight: bold;
}


.modal p:first-of-type {
  font-weight: bold;
}

.file-gallery {
  display:flex;
  min-width:950px;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 16px;
}

.file-item {
  border: 1px solid #ccc;
  color:white;
  padding: 8px;
  border-radius: 8px;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #000000;
  font-size: 12px;
}
.file-item a {
  color:white;
}
.folder-tools {
  display:block;
  max-width:500px;
  text-align: center;
  font-size:12px;
}

.magical-button {
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  color: white;
  background: linear-gradient(45deg, #15345d, #0f071b, #15345d);
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  animation: glow 1.5s infinite alternate;
  transition: transform 0.2s ease-in-out;
}

.magical-button:hover {
  transform: scale(1.1);
}

@keyframes glow {
  from {
    box-shadow: 0 0 15px rgba(255, 242, 0, 0.8), 0 0 30px rgba(255, 140, 0, 0.6), 0 0 45px rgba(64, 224, 208, 0.4);
  }
  to {
    box-shadow: 0 0 30px rgb(132, 255, 0), 0 0 60px rgba(255, 140, 0, 0.8), 0 0 90px rgba(64, 224, 208, 0.6);
  }
}
.file-thumbnail {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 8px;
}

.file-name {
  display: block;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
  margin: 0 auto;
  font-size: 12px;
  line-height: 1.2;
  padding: 4px 0;
}

.file-item img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 8px;
}
.file-search {
  max-width:400px;
}
/* Ensure images are responsive */
img.responsive {
  max-width: 100%; /* Limit image width to the width of its container */
  height: auto; /* Maintain the aspect ratio */
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  margin-top: 20px;
  max-width:100%;
  height:50px;
  margin-bottom: 20px;
  background-color:white;
  padding:10px;
  border-radius:8px;
  color:black;

}
.shareSelected {
  display: block;
}

.pagination-controls button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  width: 90px;
  cursor: pointer;

}

.pagination-controls button:hover {
  background-color: #0056b3;
}

.pagination-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
/* Adjust the tenant input container for smaller screens */
.tenant-input-container {
  color:black;
  font-size: 16px;
  display: block;
  background-color: transparent;
  max-width: 500px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: none;
  box-sizing: border-box;
}

/* Responsive adjustments for the filter container and pagination controls */
.filter-container-box {
  background-image: url("./img/space_themed_magnifying_glass_icon_cartoon__red__black__silver-20241031-023151.png");
  background-repeat: repeat-x;

}

.filter-container {
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;
  color: white;

}

@media screen and (max-width: 768px) {
  .file-item {
    width: calc(50% - 8px); /* Adjust file items for smaller screens */
  }

  .pagination-controls {
    width:500px;
  }


  .file-name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%; /* Adjust the width as necessary */
    margin: 0 auto; /* Center the filename if desired */
  }
  .file-search {
    width:50px;
  }
  .pagination-controls {
    width:500px;
  }
}
@media screen and (min-width: 401px) {
  .file-search {
    width: 400px; /* Specific width on larger screens */
  }
  .file-search-mobile {
    display:none;
  }
  div.mobile-input-container {
    display:none;
  }
}
@media screen and (max-width: 400px) {
  body {
    max-width: 350px;
  }
  .file-uploader-box {
    width: 300px;
  }
  form {
    max-width: 350px;
  }

  .folder-creation {
    float:none;
  }
  .folder-move {
    float:none;
  }
  .filter-container {
    max-width: 400px;
  }

  .file-gallery {
    display: flex;
    min-width: 300px;
    max-width: 400px;
    flex-wrap: wrap;

  }

  .manifest-upload {
    width: 300px;
  }

  .tenant-input-container {
    color: black;
    font-size: 16px;
    width: 300px;
    margin: 30px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: none;
    box-sizing: border-box;
  }
  .mobile-input-container {
    display: block;
    max-width: 300px;
    margin: 30px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  /* Adjust the footer and any other elements for smaller screens */
  .footer img.responsive {
    max-width: 80%; /* Adjust the width of footer images on very small screens */
    height: auto;
  }
  .mygallery {
    margin-top:50px;
  }

  .files img {
    width: 400px;
  }

  .file-search {
    display: none;

  }
  .items-per-page-selector {
    width:50px;
    margin-left:22px;
  }
  .file-search-mobile {
    display:block;
    border:none;
    max-width: 200px;
    margin-left:20px;
  }
  .filter-container {
    max-width:370px;
    border-style: none;
    align-self: center;
    }
  }


