/* SavedItem.css */
.saved-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
}

.saved-item p {
    margin: 0 0 5px 0;
    font-weight: bold;
}

.saved-item input {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.button-group {
    display: flex;
    gap: 10px;
}

.button-group button {
    padding: 5px 10px;
    cursor: pointer;
}

.saved-item.image {
    background-color: #f9f9f9;
}

.saved-item.web-page {
    background-color: #eef9ff;
}
