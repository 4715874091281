/* Container styling */
.evaluation-container {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    padding: 30px;
    width: 90%;
    max-width: 600px;
    margin: 40px auto;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.evaluation-title {
    font-size: 28px;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 25px;
    font-weight: bold;
}

/* Project link styling */
.project-link {
    display: block;
    text-align: center;
    margin: 20px 0;
    padding: 12px 24px;
    background: linear-gradient(135deg, #6366f1, #4f46e5);
    color: white;
    text-decoration: none;
    border-radius: 8px;
    transition: transform 0.2s, box-shadow 0.2s;
}

.project-link:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

/* Form section styling */
.evaluation-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.form-group {
    margin-bottom: 20px;
}

/* Labels */
.form-label {
    display: block;
    font-size: 16px;
    color: #4b5563;
    margin-bottom: 8px;
    font-weight: 500;
}

/* Range input styling */
.range-container {
    position: relative;
    padding: 10px 0;
}

.range-input {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    background: #e5e7eb;
    border-radius: 3px;
    outline: none;
}

.range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #4f46e5;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s;
}

.range-input::-webkit-slider-thumb:hover {
    background: #6366f1;
}

.range-value {
    position: absolute;
    right: 0;
    top: -5px;
    background: #4f46e5;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 14px;
}

/* Textarea styling */
.comments-input {
    width: 100%;
    min-height: 120px;
    padding: 12px;
    border: 2px solid #e5e7eb;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    transition: border-color 0.2s;
}

.comments-input:focus {
    border-color: #4f46e5;
    outline: none;
}

/* Button container */
.button-container {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    gap: 12px;
}

/* Navigation buttons */
.nav-button {
    padding: 10px 20px;
    background: #f3f4f6;
    border: none;
    border-radius: 6px;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.2s;
}

.nav-button:hover:not(:disabled) {
    background: #e5e7eb;
}

.nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Submit button */
.submit-button {
    padding: 12px 24px;
    background: linear-gradient(135deg, #6366f1, #4f46e5);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
}

.submit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

/* Progress section */
.progress-container {
    margin-top: 24px;
    text-align: center;
}

.progress-text {
    color: #6b7280;
    font-size: 14px;
    margin-bottom: 8px;
}

.progress-bar {
    width: 100%;
    height: 6px;
    background: #e5e7eb;
    border-radius: 3px;
    overflow: hidden;
}

.progress-fill {
    height: 100%;
    background: linear-gradient(135deg, #6366f1, #4f46e5);
    transition: width 0.3s ease;
}

/* Loading and error states */
.loading-message,
.error-message {
    text-align: center;
    padding: 20px;
    background: #f3f4f6;
    border-radius: 8px;
    color: #4b5563;
}

.error-message {
    background: #fee2e2;
    color: #dc2626;
}
.notification {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    z-index: 1000;
}

.notification.success {
    background-color: green;
}

.notification.error {
    background-color: red;
}
.notification-bar {
    margin-top: 20px;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    font-size: 1rem;
}

.notification-success {
    background-color: #d4edda; /* Green background */
    color: #155724; /* Dark green text */
    border: 1px solid #c3e6cb;
}

.notification-error {
    background-color: #f8d7da; /* Red background */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb;
}

.slider-container {
    margin: 20px 0;
    text-align: center;
}

.team-slider {
    width: 100%;
    margin-top: 10px;
}
