/* UseWithNyxPage.css */
body, html {
    background-color: black; /* Set body background color to black */
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    color: white; /* Ensure the text color is white */
    text-align: center;
}

.nyx-page {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: black; /* Ensure the background is black */
}

.content-container {
    flex-grow: 1;
    width: 100%;
    height: auto;
    padding: 20px;
    overflow: auto;
    background-color: black; /* Ensure the background is black */
    color: white; /* Ensure the text color is white */
    box-sizing: border-box;
    margin-top: 20px; /* Ensure top margin */
    font-size: 12px;

}

img {
    max-width: 100%;
    height: auto;
}

h1 {
    margin-top: 20px; /* Ensure top margin */
    color: white; /* Ensure the text color is white */
}
#pageContent {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.chat-popup {
    width: 100%;
    height: 750px;
    background-color: white;
    opacity: 90%;
    color: black;
}

.response-container {
    height: calc(100% - 80px); /* Adjust based on the size of other elements */
    overflow-y: auto;
}
.selected-files {
    font-size: 12px;
}
@media (max-width: 1024px) {

    #pageContent {
        width: 50%;
        height: 100%;
        overflow: hidden;
    }
    .chat-container.expanded {
        width: 250px
    }
    .chat-popup {
        width: 100%;
        height: 750px;
        background-color: white;
        opacity: 90%;
        color: black;
    }

    .response-container {
        height: calc(100% - 80px); /* Adjust based on the size of other elements */
        overflow-y: auto;
    }
    .selected-files {
        font-size: 12px;
    }

}
