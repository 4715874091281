.doodle-pad {
    padding: 1rem;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 1rem;
}
.doodle-pad {
    background: rgba(245, 245, 245, 0.9); /* Slightly opaque for better contrast */
}

button,
input[type="submit"] {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.filter-filenames {
    justify-self: center;
}

button:hover,
input[type="submit"]:hover {
    background-color: #0056b3;
}

.doodle-pad h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #333;
}

.canvas-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    background-color: white;
}

.doodle-controls {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.doodle-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.doodle-button:hover {
    opacity: 0.9;
}

.doodle-button.primary {
    background-color: #007bff;
    color: white;
}

.doodle-button:not(.primary) {
    background-color: #e9ecef;
    color: #333;
}
.doodle-pad {
    padding: 20px;
    background: #f5f5f5;
    opacity: 90%;
    border-radius: 10px;
    max-width: 500px;
    margin: 0 auto;
}

.control-section {
    margin: 10px 0;
    padding: 10px;
    color: black;
    border-radius: 5px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}
.control-section label {
    flex: 1; /* Make labels and inputs take equal space */
    justify-content: space-between; /* Distribute space evenly */
}

input[type="range"] {
    max-width: 150px; /* Keep sliders manageable on smaller screens */
}

.quick-colors {
    display: flex;
    gap: 5px;
}

button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

button:hover {
    transform: scale(1.05);
}

label {
    display: flex;
    align-items: center;
    gap: 10px;
}


input[type="range"] {
    width: 100px;
}

.button-section {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: center;
}

select {
    padding: 5px;
    border-radius: 5px;
    width:95%;
}



Spirograph Styles

.spiro-controls {
    background: #fff3e0;
    border: 1px solid #ffe0b2;
}

.spiro-controls label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5px;
}

.spiro-controls input[type="range"] {
    width: 120px;
}

.doodle-pad {
    padding: 20px;
    background: #f5f5f5;
    border-radius: 10px;
    max-width: 500px;
    margin: 0 auto;
}

.control-section {
    margin: 10px 0;
    padding: 10px;
    background: white;
    border-radius: 5px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.quick-colors {
    display: flex;
    gap: 5px;
}

.spiro-controls {
    background: #fff3e0;
    border: 1px solid #ffe0b2;
}

.spiro-controls label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5px;
}

.spiro-controls input[type="range"] {
    width: 120px;
}

.button-section {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: center;
}

button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

button:hover {
    transform: scale(1.05);
}

select {
    padding: 5px;
    border-radius: 5px;
}
@media (max-width: 1024px) {
    .chat-container {
        width: 40%; /* Adjust to a more flexible width */
        margin: 0 auto; /* Center it on the screen */
    }

    .doodle-pad {
        margin: 0 auto; /* Center the doodle pad */
    }

    .control-section {
        flex-direction: column; /* Stack controls vertically */
        gap: 5px; /* Reduce the gap between controls */
    }

    @media (max-width: 768px) {
        .canvas-container {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            background-color: white;
        }
        canvas {
            width:350px;
        }

    }
}
