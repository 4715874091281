/* ChatbotNYX.css */

body, html {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('./img/mena_bearcat_space.png') no-repeat center center fixed;
    background-size: cover;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}



.chat-container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    border: 1px solid #4859a6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-right: 20px;
    width: 500px;
    max-width: 500px;
    max-height: 1000px;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease;
    overflow-y: auto; /* Enables vertical scroll bar */
    overflow-x: hidden; /* Hides horizontal scroll bar, keeping the layout clean */
    background: rgba(255, 255, 255, 0.8);
    font-family: Arial, sans-serif;
}


.gallery-button-section {
    float:left;
}
form {
    padding:0px;
}
h3 {
    text-align: center;
}

.response-container {
    padding-left: 20px;
    padding-right: 20px;
}


.copy-link-container {
    margin-bottom: 10px;
    margin-right: 10px;
    text-align: center;
}

.copy-link-container p {
    margin: 0;
}
.magical-button {
    margin-top: 200px;
}
.copy-link-container button {
    margin-top: 5px;
}

.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
.chat-container {
    background: rgba(255, 255, 255, 0.8); /* Adjust the transparency so content is easier to read */
}
.success-message {
    color: green;
    font-size: 12px;
    margin-bottom: 5px;
}

button.last {
    margin-right: 10px;
}

button {
    font-size: 12px; /* Slightly larger */
    padding: 10px 15px; /* Add padding */
    border: 2px solid transparent;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

button:hover {
    background-color: #007bff;
    border-color: #fff; /* Add a border to highlight on hover */
}

.chat-toggle-button {
    width: 100%;
    padding: 8px;
    font-weight: bold;
    background: linear-gradient(135deg, #24b7a5, #4859a6);
    background-size: 200% 200%;
    animation: gradientAnimation 4s ease infinite;
    color: white;
    cursor: pointer;
    text-align: center;
    border: none;
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);

}
@keyframes flashSave {
    0% { background-color: #3b69ff; }
    50% { background-color: #49fd35; }
    100% { background-color: #3b89ff; }
}

.flash-save {
    animation: flashSave 1s ease-in-out infinite;
}
@keyframes flashGreen {
    0% { background-color: #007bff; }
    50% { background-color: #ff0008; }
    100% { background-color: #007bff; }
}

.flash-green {
    animation: flashGreen 1s infinite;
}
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(56, 54, 54, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
    display: none;
}

.loading-overlay.visible {
    display: flex;
}

.loading-indicator {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: white;
    background-color: transparent;
    animation: blinker 2s linear infinite;
    margin-bottom: 65px;
}



.generated-content {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.save-button {
    margin: 10px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #0056b3;
}

.thumbnail-container {
    display: inline-block;
    margin: 5px;
    cursor: pointer;
}

.file-icon {
    font-size: 24px;
    color: #555;
}

.file-thumbnails img.thumbnail {
    width: 24px;
    height: 24px;
    object-fit: cover;
}

.error {
    color: red;
    padding: 10px;
}
.completion-message {
    font-size: 10px;
}

input[type="text"], textarea {
    justify-self: left;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right:10px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.8); /* Add slight opacity */
    border: 1px solid #4859a6;
    color: #333; /* Darker text */
    border-radius: 5px;
    padding: 12px;
    font-size: 14px;
    width: 95%
}

input[type="text"], textarea {

}



.button-container {
    display: block;
    justify-content: space-between;
    padding-right: 10px; /* Added padding */
}

button[type="submit"], button[title="Clear Chat"], button[title="Copy Chat"] {
    background-color: #4859a6;
    color: white;
    font-weight: bold;
    border: none;
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px;
    box-sizing: border-box;
    height: 50px;
    width: 100%;
}
.help-chat-button-section {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
}
button[title="Clear Chat"] {
    background-color: pink;
    color: black;
}
.help-chat-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.help-chat-popup {
    position: fixed;
    bottom: 60px;
    left: 20px;
    width: 400px;
    height: 700px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    z-index: 1001;
}

.close-help-chat {
    position: absolute;
    top: 5px;
    float:right;
    right: 5px;
    background: lightgrey;
    opacity: 80%;
    border: none;
    cursor: pointer;
    font-size: 1em;
    color:limegreen;
}

.help-chat-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.chat {
    margin: 10px 0;
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    color: black; /* Set font color to black */
}

.user {
    background-color: #fafffe;
    padding: 5px;
    border-radius: 4px;
    color: black; /* Set font color to black */
}

.filebaby {
    background-color: #fafffe;
    padding: 5px;
    border-radius: 4px;
    color: black; /* Set font color to black */
}

.filebaby p {
    margin: 0;
    padding: 5px 0;
}

.thumbnail {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
}
@keyframes fadeIn {
    0% { opacity: 0; transform: translateY(10px); }
    100% { opacity: 1; transform: translateY(0); }
}

.chat {
    animation: fadeIn 0.5s ease;
}


@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes typingIndicator {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
}

.typing-indicator {
    font-size: 12px;
    color: #4859a6;
    animation: typingIndicator 1s infinite;
}


.chat-title-bar .nyx {
    font-family: 'VTCMarsha', sans-serif;
    font-size: 30px;
    background-color: transparent;
    color: black; /* Set font color to black */
    padding: 10px;
    cursor: move;
    text-align: center;
    border-radius: 8px 8px 0 0;
}

.generated-page {
    margin-top: 20px;
    padding: 10px;
}

.generated-page p {
    margin: 10px 0;
    font-size: 16px;
    color: black; /* Set font color to black */
}
@media (max-width: 1024px) {
    .chat-container {
        width: 80%; /* Adjust to a more flexible width */
        margin: 0 auto; /* Center it on the screen */
    }

    .doodle-pad {
        margin: 0 auto; /* Center the doodle pad */
    }

    .control-section {
        flex-direction: column; /* Stack controls vertically */
        gap: 5px; /* Reduce the gap between controls */
    }
}

/* ChatbotNYX.css */

/* Ensure that the saved items have some spacing */
.saved-item {
    margin-top: 15px;
}

/* Optional: Adjust input field appearance within SavedItem */
.saved-item input {
    background-color: #fff;
    border: 1px solid #ddd;
}

/* Optional: Style buttons within SavedItem */
.saved-item button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
}

.saved-item button:hover {
    background-color: #0056b3;
}

@media (max-width: 1024px) {
    .chat-container.expanded {
        width: 200px;
        max-width: 200px;
    }
    .loading-indicator {
        font-size: 12px;
    }
    .chat-popup {
        width: 50%;

    }
    .chat-container {
        width: 90%; /* Make chat container smaller on mobile */
        bottom: 20px; /* Lift it off the bottom a bit */

    }

}


/* Media queries for responsive design */
@media (min-width: 768px) {
    .chat-container.expanded {
        width: 500px;
        max-width: 500px;
    }
    .loading-indicator {
        font-size: 12px;
    }
    .chat-popup {
        width: 100%;
    }
    .chat-container {
         width: 90%; /* Make chat container smaller on mobile */
         bottom: 20px; /* Lift it off the bottom a bit */
     }
}
